<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    mobile-breakpoint="960"
    :width="$vuetify.breakpoint.smAndDown || fullWidth?235:80"
    class="app-navigation-menu"
    :class="$vuetify.breakpoint.smAndDown || fullWidth?'pa-0':'pa-0'"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center pl-4">
      <div
        class="d-flex align-center text-decoration-none pa-3"
        @click="fullWidth=!fullWidth"
      >
        <v-icon class="float-chevron d-none d-md-block">{{ fullWidth?icons.mdiChevronLeft:icons.mdiChevronRight }}</v-icon> 
        <v-img
          :src="require('@/assets/images/logos/logo.svg')"
          max-height="30px"
          max-width="50px"
          alt="logo"
          contain
          eager
          class="app-logo me-5"
        ></v-img>
        
        <v-slide-x-transition>
          <h2 class="text--primary underlined ml-4 app-title">
            {{ $t('message.apptitle') }}
          </h2>
        </v-slide-x-transition>   

      </div>
    </div>

    <!-- Navigation Items -->
    
    <v-list
      expand
      class="vertical-nav-menu-items ma-1 px-0 pb-15"
    >

         
      <nav-menu-link
        :title="$t('message.page-titles.transactions')"
        :to="{ name: 'transactions' }"
        :icon="icons.mdiSwapHorizontalBold"
        :enabled="true"
        :badge="$store.getters.transactionsCount"
      >
      </nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.teams')"
        :to="{ name: 'teams' }"
        :icon="icons.mdiAccountGroupOutline"
        :enabled="resources.some(item => item === 'teams')"
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.players')"
        :to="{ name: 'players' }"
        :icon="icons.mdiAccountBoxOutline"
        :enabled="resources.some(item => item === 'players')"
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.draft')"
        :to="{ name: 'drafts' }"
        :icon="icons.mdiAccountArrowUpOutline"
        :enabled="resources.some(item => item === 'draft_players')"
      ></nav-menu-link>

      <!--
      <nav-menu-link
        disabled
        :title="$t('message.page-titles.discipline')"
        :to="{ name: 'discipline' }"
        :icon="icons.mdiAccountDetailsOutline"
        :enabled="true"
      ></nav-menu-link>
      -->

      <nav-menu-link
        :title="$t('message.page-titles.contracts')"
        :to="{ name: 'contracts' }"
        :icon="icons.mdiFileSign"
        :enabled="resources.some(item => item === 'contracts')"
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.rosters')"
        :to="{ name: 'rosters' }"
        :icon="icons.mdiTransitTransfer"
        :enabled="resources.some(item => item === 'rosters')"
      ></nav-menu-link>           
      
      <nav-menu-link
        :title="$t('message.page-titles.reports')"
        :to="{ name: 'reports' }"
        :icon="icons.mdiFileChartOutline"
        :enabled=true
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.pensions')"
        :to="{ name: 'pensions' }"
        :icon="icons.mdiAccountCash"
        :enabled=true
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.schedule')"
        :to="{ name: 'schedule' }"
        :icon="icons.mdiCalendarClockOutline"
        :enabled=true
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.distributions')"
        :to="{ name: 'distributions' }"
        :icon="icons.mdiEmailFastOutline"
        :enabled="this.$store.getters.resourceHas('dailystatusreports', 'read')"
      ></nav-menu-link>


      <nav-menu-link
        :title="$t('message.page-titles.negotiations')"
        :to="{ name: 'lists' }"
        :icon="icons.mdiPlaylistStar"
        :enabled="resources.some(item => item === 'player_requests')"
      ></nav-menu-link>


      <nav-menu-link
        :title="$t('message.page-titles.player_requests')"
        :to="{ name: 'player_requests' }"
        :icon="icons.mdiCardAccountDetailsStarOutline"
        :enabled="resources.some(item => item === 'player_requests')"
        :badge="$store.getters.requestsCount"
      ></nav-menu-link>

<!-- ------------------ ADMIN SECTION ------------------------ -->

      <nav-menu-section-title :title="$vuetify.breakpoint.smAndDown || fullWidth ? $t('message.page-titles.admin') : null"
        :enabled="anyAdminResourceUpdateAccess">
      </nav-menu-section-title>

      <nav-menu-link
        :title="$t('message.page-titles.agents')"
        :to="{ name: 'agents' }"
        :icon="icons.mdiAccountTie"
        :enabled="this.$store.getters.resourceHas('agents', 'update')"
      ></nav-menu-link>
      
      <nav-menu-link
        :title="$t('message.page-titles.fixtures')"
        :to="{ name: 'games' }"
        :icon="icons.mdiFootball"
        :enabled="this.$store.getters.resourceHas('fixtures', 'update')"
      ></nav-menu-link>
      
      <nav-menu-link
        :title="$t('message.page-titles.seasons')"
        :to="{ name: 'seasons' }"
        :icon="icons.mdiCalendarMonth"
        :enabled="this.$store.getters.resourceHas('seasons', 'update')"
      ></nav-menu-link>
  
      <nav-menu-link
        :title="$t('message.page-titles.addendums')"
        :to="{ name: 'addendums' }"
        :icon="icons.mdiBookAccount"
        :enabled="this.$store.getters.resourceHas('contractphrases', 'update')"
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.addendum_groups')"
        :to="{ name: 'addendumgroups' }"
        :icon="icons.mdiFolderOpen"
        :enabled="this.$store.getters.resourceHas('contractphrasegroups', 'update')"
      ></nav-menu-link>
  
      <nav-menu-link
        :title="$t('message.page-titles.colleges')"
        :to="{ name: 'colleges' }"
        :icon="icons.mdiSchool"
        :enabled="this.$store.getters.resourceHas('colleges', 'update')"
      ></nav-menu-link>

      <nav-menu-link
        :title="$t('message.page-titles.venues')"
        :to="{ name: 'venues' }"
        :icon="icons.mdiOfficeBuildingMarker"
        :enabled="this.$store.getters.resourceHas('venues', 'update')"
      ></nav-menu-link>

      <!-- SAMPLE NAV GROUP
      <nav-menu-group
        :title="$t('message.page-titles.admin')"
        :to="{ name: 'admin' }"
        :icon="icons.mdiAccountCogOutline"
      >
      </nav-menu-group> -->

      <!-- TEMPLATE SAMPLE LINKS
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiCalendarMonth"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link>
      -->

    </v-list>

  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiCalendarMonth,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountGroupOutline,
  mdiContacts,
  mdiAccountBoxOutline,
  mdiAccountArrowUpOutline, 
  mdiAccountDetailsOutline,
  mdiFileSign,
  mdiTransitTransfer,
  mdiCalendarClockOutline,
  mdiOfficeBuildingMarker,
  mdiSchool,
  mdiEmailFastOutline,
  mdiBookAccount,
  mdiAccountTie,
  mdiSwapHorizontalBold,
  mdiFileChartOutline,
  mdiFootball,
  mdiBook,
  mdiFolderOpen,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCardAccountDetailsStarOutline,
  mdiPlaylistStar,
  mdiAccountCash
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },

  data: () => ({
    fullWidth: true
  }),

  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
    resources: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    anyAdminResourceUpdateAccess() {
      return this.$store.getters.resourceHas('agents', 'update') || 
      this.$store.getters.resourceHas('contractphrases', 'update') ||
      this.$store.getters.resourceHas('contractphrasegroups', 'update') ||
      this.$store.getters.resourceHas('colleges', 'update') ||
      this.$store.getters.resourceHas('venues', 'update') ||
      this.$store.getters.resourceHas('seasons', 'update') ||
      this.$store.getters.resourceHas('fixtures', 'update')
    }
  },

      

  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiContacts,
        mdiCalendarMonth,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccountGroupOutline,
        mdiAccountBoxOutline,
        mdiAccountArrowUpOutline,
        mdiAccountDetailsOutline,
        mdiFileSign,
        mdiTransitTransfer,
        mdiCalendarClockOutline,
        mdiOfficeBuildingMarker,
        mdiSchool,
        mdiEmailFastOutline,
        mdiBookAccount,
        mdiAccountTie,
        mdiSwapHorizontalBold,
        mdiFileChartOutline, 
        mdiFootball,
        mdiBook,
        mdiFolderOpen,
        mdiCalendarMonth,
        mdiChevronLeft,
        mdiChevronRight,
        mdiCardAccountDetailsStarOutline,
        mdiPlaylistStar,
        mdiAccountCash
      },
    }
  },
}
</script>

<style lang="sass">

@include theme(app-navigation-menu) using ($material)
  &.v-navigation-drawer
    background-color: map-deep-get($material, 'background-bar')

.app-title
  font-size: 1.25rem
  font-weight: 700
  font-stretch: normal
  font-style: normal
  line-height: normal
  letter-spacing: 0.3px

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo
  transition: all 0.18s ease-in-out

.v-navigation-drawer
  border-right: 1px solid #55555555 

.v-navigation-drawer--mini-variant
  transform: translateX(-4px)

  &.vertical-nav-menu-link
    ::v-deep .v-list-item__icon
      .v-icon
        transition: none !important

.float-chevron
  position: absolute !important
  left: 5px
  padding-top: 5px

.v-navigation-drawer

  .v-list-item--link:before
    background-color: none

  .v-list-item--active
    border-radius: 5px !important

  .v-list-item--active::before
    opacity: 0.35
    height: 100%
    width: 8px
    border-radius: 5px 0 0 5px

</style>
