import APIRequest from './_api_request'

export default class LookupApi {

    constructor() {
        this.lookup_teams = {
            data: null,
            lastLookup: Date.now()
        }
        this.lookup_players = {
            data: null,
            lastLookup: Date.now()
        }
        this.lookup_agents = {
            data: null,
            lastLookup: Date.now()
        } 
        this.lookup_addendums = {
            data: null,
            lastLookup: Date.now()
        }
        this.lookup_addendumGroups = {
            data: null,
            lastLookup: Date.now()
        }  
        this.lookup_colleges = {
            data: null,
            lastLookup: Date.now()
        }
        this.lookup_venues = {
            data: null,
            lastLookup: Date.now()
        }
        this.lookup_seasons = {
            data: null,
            lastLookup: Date.now()
        }

        this.lookup_draftPlayers = {  
            data: null,
            lastLookup: Date.now()
        }
    }

    refresh(checkObject) {
        if (checkObject.data == null) { return true }
        if (Date.now() - checkObject.lastLookup > 30000) {
            checkObject.lastLookup = Date.now()
            return true
        }
        return false
    }

    addTeam(t) {
        if (this.lookup_teams.data) {
            this.lookup_teams.data.push(t)
        }
    }
    // all lookup list items for dropdown and typeahead selects.  Usually just ID and Name
    async getTeamsList() {
        if (this.refresh(this.lookup_teams)) {
            this.lookup_teams.data = await APIRequest.getApiRequest(`/api/teams/?sort_by=name&sort_order=asc&limit=*`)
        }
        return this.lookup_teams.data.filter(a => a.name != null).sort((a,b) => a.name.localeCompare(b.name))
    }

    addDraftPlayer(dp) {
        if (this.lookup_draftPlayers.data) {
            this.lookup_draftPlayers.data.push(dp)
        }
    }

    async getDraftPlayersList(q) {
        if (this.refresh(this.lookup_draftPlayers)) {
            this.lookup_draftPlayers.data = await APIRequest.getApiRequest(`/api/draftplayers/lookup`)
        }
        return this.lookup_draftPlayers.data.filter(a => a.name != null).filter(a => a.name.toLowerCase().includes(q.toLowerCase()) == true)
        return this.lookup_draftPlayers.data.sort((a,b) => a.name.localeCompare(b.name))
    }

    async getDraftPlayersListByID(id) {
        if (this.refresh(this.lookup_draftPlayers)) {
            this.lookup_draftPlayers.data = await APIRequest.getApiRequest(`/api/draftplayers/lookup`)
        }
        return this.lookup_draftPlayers.data.filter(a => a.ID == id)
    }

    addPlayer(p) {
        if (this.lookup_players.data) {
            this.lookup_players.data.push(p)
        }
    }

    async getPlayersList(q) {
        if (this.refresh(this.lookup_players)) {
            this.lookup_players.data = await APIRequest.getApiRequest(`/api/players/lookup`)
        }
        return this.lookup_players.data.filter(a => a.name != null).filter(a => a.name.toLowerCase().includes(q.toLowerCase()) == true)
        return this.lookup_players.data.sort((a,b) => a.name.localeCompare(b.name))
    }

    async getPlayersListByID(id) {
        if (this.refresh(this.lookup_players)) {
            this.lookup_players.data = await APIRequest.getApiRequest(`/api/players/lookup`)
        }
        return this.lookup_players.data.filter(a => a.ID == id)
    }

    addAgent(a) {
        if (this.lookup_agents.data) {
            this.lookup_agents.data.push(a)
        }
    }

    async getAgentsList() {
        if (this.refresh(this.lookup_agents)) {
            this.lookup_agents.data = await APIRequest.getApiRequest(`/api/agents/lookup?with=`)
        }
        return this.lookup_agents.data.filter(a => a.name != null).sort((a,b) => a.name.localeCompare(b.name))
    }

    addAddendum(a) {
        if (this.lookup_addendums.data) {
            this.lookup_addendums.data.push(a)
        }
    }

    async getAddendumsList() {
        if (this.refresh(this.lookup_addendums)) {
            this.lookup_addendums.data = await APIRequest.getApiRequest(`/api/contractphrases?with=parent_group&limit=*`)
        }
        return this.lookup_addendums.data.filter(a => a.title != null).sort((a,b) => a.title.localeCompare(b.title))
    }

    addAddendumGroup(a) {
        if (this.lookup_addendums.data) {
            this.lookup_addendums.data.push(a)
        }
    }


    async getAddendumGroupsList() {
        if (this.refresh(this.lookup_addendumGroups)) {
            this.lookup_addendumGroups.data = await APIRequest.getApiRequest(`/api/contractphrasegroups?limit=*`)
        }
        return this.lookup_addendumGroups.data.sort((a,b) => { return b - a })
    }

    addCollege(c) {
        if (this.lookup_colleges.data) {
            this.lookup_colleges.data.push(c)
        }
    }

    async getCollegesList() {
        if (this.refresh(this.lookup_colleges)) {
            this.lookup_colleges.data = await APIRequest.getApiRequest(`/api/colleges/?sort_by=name&sort_order=asc&only=name&limit=*`)
        }
        return this.lookup_colleges.data.filter(a => a.name != null).sort((a,b) => a.name.localeCompare(b.name))
    }

    addVenue(v) {
        if (this.lookup_venues.data) {
            this.lookup_venues.data.push(v)
        }
    }
     // all lookup list items for dropdown and typeahead selects.  Usually just ID and Name
    async getVenuesList() {
        if (this.refresh(this.lookup_venues)) {
            this.lookup_venues.data = await APIRequest.getApiRequest(`/api/venues/?sort_by=name&sort_order=asc&limit=*`)
        }
        return this.lookup_venues.data.filter(a => a.name != null).sort((a,b) => a.name.localeCompare(b.name))
    }

    addSeason(s) {
        if (this.lookup_seasons.data) {
            this.lookup_seasons.data.push(s)
        }
    }
    async getSeasonsList() {
        if (this.refresh(this.lookup_seasons)) {
            this.lookup_seasons.data = await APIRequest.getApiRequest(`/api/seasons/?sort_by=year&sort_order=asc&only=year&limit=*`)
        }
        return this.lookup_seasons.data;
    }

}