import Vue from 'vue'
import VueRouter from 'vue-router'
import requireAuth from './_auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/transactions/Transactions.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Transactions"
    }
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/views/teams/Teams.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Teams"
    }
  },
  {
    path: '/teams/:id',
    name: 'team',
    component: () => import('@/views/teams/TeamPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Team"
    }
  },
  {
    path: '/colleges',
    name: 'colleges',
    component: () => import('@/views/colleges/Colleges.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Colleges"
    }
  },
  {
    path: '/colleges/:id',
    name: 'college',
    component: () => import('@/views/colleges/CollegePage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - College"
    }
  },
  {
    path: '/venues',
    name: 'venues',
    component: () => import('@/views/venues/Venues.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Venues"
    }
  },
  {
    path: '/venues/:id',
    name: 'venue',
    component: () => import('@/views/venues/VenuePage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Venue"
    }
  },
  {
    path: '/players',
    name: 'players',
    component: () => import('@/views/players/Players.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Players"
    }
  },
  {
    path: '/players/:id',
    name: 'player',
    component: () => import('@/views/players/PlayerPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Player"
    }
  },
  {
    path: '/drafts',
    name: 'drafts',
    component: () => import('@/views/drafts/DraftPlayers.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Draft Players"
    }
  },
  {
    path: '/drafts/:id',
    name: 'draft',
    component: () => import('@/views/drafts/DraftPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Draft Player"
    }
  },
  {
    path: '/agents',
    name: 'agents',
    component: () => import('@/views/agents/Agents.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Agents"
    }
  },
  {
    path: '/agents/:id',
    name: 'agent',
    component: () => import('@/views/agents/AgentPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Agent"
    }
  },
  {
    path: '/player_requests',
    name: 'player_requests',
    component: () => import('@/views/requests/PlayerRequests.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Player Requests"
    }
  },
  {
    path: '/player_requests/:id/:type',
    name: 'player_request',
    component: () => import('@/views/requests/PlayerRequestsPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Player Request"
    }
  },
  {
    path: '/lists',
    name: 'lists',
    component: () => import('@/views/requests/lists/Lists.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Players Lists"
    }
  },
  {
    path: '/lists/:team/:type',
    name: 'player_lists',
    component: () => import('@/views/requests/lists/PlayerStateList.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Players List"
    }
  },
  {
    path: '/rosters',
    name: 'rosters',
    component: () => import('@/views/rosters/Rosters.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Rosters"
    }
  },
  {
    path: '/rosters/:id',
    name: 'roster',
    component: () => import('@/views/rosters/RosterPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Roster"
    }
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: () => import('@/views/contracts/Contracts.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Contracts"
    }
  },
  {
    path: '/contracts/:id',
    name: 'contract',
    component: () => import('@/views/contracts/ContractPage.vue'),
    props: {
      default: true
    },
    beforeEnter: requireAuth,
    meta: {
      title: "Contract"
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/Reports.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Reports"
    }
  },
  {
    path: '/reports/:name',
    name: 'report',
    component: () => import('@/views/reports/ReportPage.vue'),
    props: {
      default: true
    },
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Report"
    }
  },
  {
    path: '/pensions',
    name: 'pensions',
    component: () => import('@/views/pensions/PensionsDuesPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Pensions"
    }
  },
  {
    path: '/seasons',
    name: 'seasons',
    component: () => import('@/views/seasons/Seasons.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Seasons"
    }
  },
  {
    path: '/seasons/:id',
    name: 'season',
    component: () => import('@/views/seasons/SeasonPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Season"
    }
  },
  {
    path: '/discipline',
    name: 'discipline',
    component: () => import('@/views/teams/Teams.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Discipline"
    }
  },
  {
  
    path: '/games',
    name: 'games',
    component: () => import('@/views/fixtures/Fixtures.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Games"
    }
  },
  {
    path: '/games/:id',
    name: 'game',
    component: () => import('@/views/fixtures/FixturePage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Game"
    }
  },
  {  
    path: '/gamerosters',
    name: 'gamerosters',
    component: () => import('@/views/fixture_rosters/FixtureRosters.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Game Rosters"
    }
  },
  {
    path: '/gamerosters/:id',
    name: 'gameroster',
    component: () => import('@/views/fixture_rosters/FixtureRosterPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Game Roster"
    }
  },
  {
    path: '/addendums',
    name: 'addendums',
    component: () => import('@/views/addendums/Addendums.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Legal"
    }
  },
  {
    path: '/addendumgroups',
    name: 'addendumgroups',
    component: () => import('@/views/addendum_groups/AddendumGroups.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Legal Group"
    }
  },
  {
    path: '/addendumgroups/:id',
    name: 'addendumgroup',
    component: () => import('@/views/addendum_groups/AddendumGroupPage.vue'),
    props: {
      default: true
    },
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Legal Group Item"
    }
  },
  {
    path: '/addendums/:id',
    name: 'addendum',
    component: () => import('@/views/addendums/AddendumPage.vue'),
    props: {
      default: true
    },
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Legal Item"
    }
  },
  {
    path: '/distributions',
    name: 'distributions',
    component: () => import('@/views/distributions/Distributions.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Distributions"
    }
  },  
  {
    path: '/distribution/:id',
    name: 'distribution',
    component: () => import('@/views/distributions/DistributionPage.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Distribution"
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('@/views/schedule/Schedule.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Schedule"
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/teams/Teams.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Admin"
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/help/Help.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: "PIMS - Help"
    }
  },

  // OTHER DEMO ROUTES
  {
    path: '/demo/dashboard',
    name: 'dashboard',
    component: () => import('@/views/_dashboard/Dashboard.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/demo/typography',
    name: 'typography',
    component: () => import('@/views/_typography/Typography.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/demo/icons',
    name: 'icons',
    component: () => import('@/views/_icons/Icons.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/demo/cards',
    name: 'cards',
    component: () => import('@/views/_cards/Card.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/demo/tables',
    name: 'simple-table',
    component: () => import('@/views/_simple-table/SimpleTable.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/demo/forms',
    name: 'form-layouts',
    component: () => import('@/views/_form-layouts/FormLayouts.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/demo/settings',
    name: 'pages-account-settings',
    component: () => import('@/views/_pages/account-settings/AccountSettings.vue'),
    beforeEnter: requireAuth,
  },
  // -- END DEMO ROUTES
  
  // Basic login and error catching 404
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      title: "PIMS - Login"
    },
  },
  {
    path: '/access-denied',
    name: 'error-403',
    component: () => import('@/views/AccessDenied.vue'),
    meta: {
      layout: 'blank',
      title: "PIMS - 403"
    },
  },
  {
    path: '/server-unavailable',
    name: 'error-server',
    component: () => import('@/views/ServerUnavailable.vue'),
    meta: {
      layout: 'blank',
      title: "PIMS - Server Error"
    },
  },
  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      title: "PIMS - 404"
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
